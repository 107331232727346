/*
=============== 
Global Styles
===============
*/

:root {
  --clr-bcg: #fff;
  --clr-font: #282c35;
  --clr-primary: #d23669;
}

.dark-theme {
  --clr-bcg: #282c35;
  --clr-font: #fff;
  --clr-primary: #ffa7c4;
}
.light-theme {
  --clr-bcg: #fff;
  --clr-font: #282c35;
  --clr-primary: #d23669;
}
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-bcg);
  color: var(--clr-font);
  line-height: 1.5;
  font-size: 0.875rem;
  transition: all 0.3s linear;
}

/*
=============== 
Navbar
===============
*/
.nav-center {
  width: 90vw;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
}
.nav-center h1 {
  font-size: 2.5rem;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.articles {
  padding: 5rem 0;
  width: 90vw;
  max-width: 600px;
  margin: 0 auto;
}
.post {
  margin-bottom: 3rem;
}
.post h2 {
  color: var(--clr-primary);
  text-transform: capitalize;
  letter-spacing: 2px;
  font-size: 1.75rem;
}
.post-info {
  margin-bottom: 0.75rem;
  font-style: italic;
}
.post-info span {
  margin-right: 0.5rem;
}

.btn {
  background: var(--clr-primary);
  color: var(--clr-bcg);
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  border-color: transparent;
  text-transform: capitalize;
  transition: all 0.3s linear;
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;
}
